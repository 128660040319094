<template>
  <div>
    <AppSidebar v-if="sdBar" />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader :user="authUser" />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
      <Toast />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Toast from '@/components/Toast.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    Toast,
  },
  data() {
    return {
      sdBar: false,
    }
  },
  computed: {
    ...mapState(['authUser']),
  },
  created() {
    this.checkDataAuthorizedUser()
  },
  methods: {
    ...mapActions(['getUser']),
    async checkDataAuthorizedUser() {
      if (!this.authUser || !this.authUser.id) {
        // tetep harus kasih ini kalau session cookies expired
        await this.getUser()
        this.sdBar = true
      } else {
        this.sdBar = true
      }
    },
  },
}
</script>
