// /* eslint-disable */
import axiosInstance from '@/libs/axios-instance'
import { createStore } from 'vuex'
import { setCookie } from '@/libs/utils'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    authUser: {},
    // permissions: [],
    toasts: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setAuthUser(state, user) {
      var obj = { ...user }
      // state.permissions = obj.permissions
      // delete obj['permissions']
      state.authUser = obj
    },
    setLogout(state) {
      // state.permissions = null
      state.authUser = null
      state.toasts = []
    },
    setToast(state, toast) {
      state.toasts.push(toast)
    },
    clearToast(state) {
      state.toasts = []
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const authData = await axiosInstance.post('auth/login', {
          username: credentials.username,
          password: credentials.password,
        })
        const token = authData.data.token
        if (token.plainTextToken) {
          setCookie('accessToken', token.plainTextToken, token.accessToken.expires_at)
          commit('setAuthUser', authData.data.user)
          return authData.data
        }
        console.log(authData.data.msg)
      } catch (error) {
        return error
      }
    },
    async getUser({ commit }) {
      let usr = {}
      await axiosInstance
        .get('me')
        .then((res) => {
          console.log('ambil data user berhasil', res)
          commit('setAuthUser', res.data.user)
          usr = res.data.user
        })
        .catch(({ response }) => {
          usr = response
          if (response.status == 401) {
            Cookies.remove('user')
            // Cookies.remove('permissions')
            Cookies.remove('accessToken')
            commit('setLogout')
          } else {
            console.log('Ambil data user:', response.statusText)
          }
        })
      return usr
    },
    async prepareLogout({ commit }, server = true) {
      if (server) {
        await axiosInstance.post('auth/logout').catch(({ response }) => {
          if (response.status == 401) {
            Cookies.remove('user')
            // Cookies.remove('permissions')
            Cookies.remove('accessToken')
            commit('setLogout')
          }
        })
      }
      Cookies.remove('user')
      // Cookies.remove('permissions')
      Cookies.remove('accessToken')
      commit('setLogout')
    },
    setToast({ commit }, toast) {
      commit('setToast', toast)
    },
    clearToast({ commit }) {
      commit('clearToast')
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'user',
      storage: {
        getItem: (key) => (Cookies.get(key) ? JSON.parse(Cookies.get(key)) : null),
        setItem: (key, value) => Cookies.set(key, JSON.stringify(value)),
        removeItem: (key) => Cookies.remove(key),
      },
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => JSON.parse(value),
      reducer: (state) => ({
        sidebarUnfoldable: state.sidebarUnfoldable,
        authUser: state.authUser,
      }),
    }),
    // createPersistedState({
    //   key: 'permissions',
    //   storage: {
    //     getItem: (key) => (Cookies.get(key) ? JSON.parse(Cookies.get(key)) : null),
    //     setItem: (key, value) => Cookies.set(key, JSON.stringify(value)),
    //     removeItem: (key) => Cookies.remove(key),
    //   },
    //   serialize: (value) => JSON.stringify(value),
    //   deserialize: (value) => JSON.parse(value),
    //   reducer: (state) => ({
    //     permissions: state.permissions,
    //   }),
    // }),
  ],
})
