<template>
  <CFooter>
    <div>
      <a href="https://coreui.io" target="_blank">Klinik Rachel</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} Winosa Mitra.</span>
    </div>
    <!-- <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
