/**
 * @module Utils
 */
/* Environment */

/**
 * Returns an environment variable
 *
 * @param key {string} A key to be retrieved by
 * @returns {*}
 */
function getEnv(key) {
  return process.env[`VUE_APP_${key.toUpperCase()}`]
}

function baseUrl() {
  const url = process.env.VUE_APP_SITE_URL
  return url.substring(url.length - 1, url.length) === '/' ? url.slice(0, -1) : url
}

function getApiHttp() {
  if (localStorage.getItem('API_HTTP')) {
    return process.env[`VUE_APP_${localStorage.getItem('API_HTTP')}_API_HTTP`]
  }
  return process.env.VUE_APP_API_HTTP
}
/**
 * Returns true if production environment
 *
 * @returns {boolean}
 */
function isProd() {
  return getEnv('env') === 'production'
}

/**
 * Returns true if dev environment
 *
 * @returns {boolean}
 */
function isDev() {
  return getEnv('env') === 'development' || !getEnv('env')
}

/**
 * Returns true if test environment
 *
 * @returns {boolean}
 */
function isStaging() {
  return getEnv('env') === 'staging'
}

/**
 * Returns true if test environment
 *
 * @returns {boolean}
 */
function isTest() {
  return getEnv('env') === 'test'
}

/**
 * Returns true if mobile environment
 *
 * @returns {boolean}
 */

function isMobile /* istanbul ignore next */() {
  return !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
}

/* Strings */

/**
 * Capitalizes first letters of each word
 * @param str {string} A string to be processed
 * @returns {string}
 * @example
 * ucwords('This is a test') // This Is A Test
 * ucwords('This IS a TEST') // This IS A TEST
 */
function ucwords(str) {
  return `${str}`.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase())
}

function firstLetterCapital(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Capitalizes first letters of each word after space
 *
 * @deprecated Use `ucwords` instead
 * @param string {string} A string to be processed
 * @returns {*}
 * @example
 * capitaliseFirstLetterAfterSpace('This is a test') // This Is A Test
 * capitaliseFirstLetterAfterSpace('This IS a TEST') // This IS A TEST
 */
function capitaliseFirstLetterAfterSpace(string) {
  return string.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  )
}

/**
 * Makes first letter of str lowercase
 *
 * @param str {string} A string to be processed
 * @returns {string}
 * @example
 * lowerCaseFirst('This is a test') // this Is a test
 * lowerCaseFirst('THIS IS a TEST') // tHIS IS a TEST
 * lowerCaseFirst('THIS IS') // tHIS IS
 */
function lowerCaseFirst(str) {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

function setCookie(cname, cvalue, exdays = null) {
  let expires = null
  if (exdays) {
    expires = 'expires=' + exdays
  } else {
    const d = new Date()
    d.setTime(d.getTime() + (180 * 24 * 60 * 60 * 1000))
    expires = 'expires=' + d.toUTCString()
  }
  document.cookie = cname + '=' + cvalue + ';' + expires + '; path=/'
}

function getCookie(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

function toRP(num = 0, comma = 2) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: comma,
  }).format(parseInt(num) || 0)
}

function stringDate(format = 'dd-mm-yyyy', dt = null, lang = 'id-ID') {
  let d = dt ? new Date(dt) : new Date()
  let hsl = format
  if (format.search(/hh/gi) >= 0) {
    hsl = hsl.replace('hh', new Intl.DateTimeFormat(lang, { hour: '2-digit' }).format(d))
  }
  if (format.search(/ii/gi) >= 0) {
    hsl = hsl.replace('ii', new Intl.DateTimeFormat(lang, { minute: '2-digit' }).format(d))
  }
  if (format.search(/dd/g) >= 0) {
    hsl = hsl.replace('dd', new Intl.DateTimeFormat(lang, { day: '2-digit' }).format(d))
  }
  if (format.search(/mm/g) >= 0) {
    hsl = hsl.replace('mm', new Intl.DateTimeFormat(lang, { month: '2-digit' }).format(d))
  }
  if (format.search(/MM/g) >= 0) {
    hsl = hsl.replace('MM', new Intl.DateTimeFormat(lang, { month: 'long' }).format(d))
  }
  if (format.search(/yyyy/gi) >= 0) {
    hsl = hsl.replace('yyyy', new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(d))
  } else if (format.search(/yy/gi) >= 0) {
    hsl = hsl.replace('yy', new Intl.DateTimeFormat(lang, { year: '2-digit' }).format(d))
  }
  return hsl
}

function longDate(dt) {
  const tgl = new Date(dt)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return tgl.toLocaleDateString('id-ID', options)
}

function dateDiff(dt1, dt2) {
  const d1 = new Date(dt1)
  const d2 = new Date(dt2)
  if (d1 < d2) {
    return 0
  }
  const diff = Math.abs(d1 - d2)
  const div = 1000 * 60 * 60 * 24 // milisecond to hour
  return Math.round(diff / div)
}

function addNums(...num) {
  let sum = 0
  for (let i of num) {
    sum += parseInt(i) || 0
  }
  return sum
}

function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

function notUsername(txt) {
  const reg = /[^a-zA-Z0-9_]/g
  const hsl = txt.search(reg)
  if (hsl < 0) {
    return false
  }
  return true
}

export {
  getEnv,
  baseUrl,
  getApiHttp,
  isProd,
  isDev,
  isStaging,
  isTest,
  isMobile,
  ucwords,
  firstLetterCapital,
  capitaliseFirstLetterAfterSpace,
  lowerCaseFirst,
  setCookie,
  getCookie,
  toRP,
  stringDate,
  longDate,
  dateDiff,
  addNums,
  createDebounce,
  notUsername,
}
