import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '@/libs/utils'
import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { roles: ['Super User', 'Admin', 'Owner', 'Dokter', 'Pasien'] },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/registrasi',
        name: 'Registrasi',
        meta: { roles: ['Super User', 'Admin'] },
        component: () => import('@/views/booking-antrian/Registrasi.vue'),
      },
      {
        path: '/pemeriksaan',
        name: 'Pemeriksaan',
        redirect: '/pemeriksaan/riwayat',
        meta: { roles: ['Super User', 'Dokter'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/pemeriksaan/add',
            name: 'Tambah Pemeriksaan',
            props: {
              formType: 'add',
            },
            component: () => import('@/views/pemeriksaan/Pemeriksaan'),
          },
          {
            path: '/pemeriksaan/riwayat',
            name: 'Riwayat',
            meta: { roles: ['Super User', 'Admin', 'Dokter'] },
            component: () => import('@/views/pemeriksaan/Riwayat'),
          },
          {
            path: '/pemeriksaan/:id/proses',
            name: 'Proses Pemeriksaan',
            props: {
              formType: 'edit',
            },
            component: () => import('@/views/pemeriksaan/Pemeriksaan'),
          },
          {
            path: '/pemeriksaan/:id/view',
            name: 'Detail Pemeriksaan',
            meta: { roles: ['Super User', 'Admin', 'Dokter', 'Owner'] },
            props: {
              formType: 'view',
            },
            component: () => import('@/views/pemeriksaan/Pemeriksaan'),
          },
        ],
      },
      {
        path: '/jadwal',
        name: 'Jadwal',
        redirect: '/jadwal/list',
        meta: { roles: ['Super User', 'Admin'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/jadwal/list',
            name: 'List Jadwal',
            component: () => import('@/views/jadwal/ListJadwal'),
          },
          {
            path: '/booking/list',
            name: 'List Booking',
            component: () => import('@/views/booking-antrian/ListBooking'),
          },
          {
            path: '/antrian/list',
            name: 'List Antrian',
            component: () => import('@/views/booking-antrian/ListAntrian'),
          },
        ],
      },
      {
        path: '/master-data',
        name: 'Master Data',
        redirect: '/master-data/staf',
        meta: { roles: ['Super User', 'Admin'] },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/master-data/dokter',
            name: 'Dokter',
            redirect: '/master-data/dokter/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/dokter/list',
                name: 'List Dokter',
                component: () => import('@/views/master-data/dokter/ListDokter'),
              },
              {
                path: '/master-data/dokter/tambah',
                name: 'Tambah Dokter',
                component: () => import('@/views/master-data/dokter/HalamanForm'),
              },
              {
                path: '/master-data/dokter/edit/:id',
                name: 'Edit Dokter',
                component: () => import('@/views/master-data/dokter/HalamanForm'),
              },
            ],
          },
          // {
          //   path: '/master-data/cabang',
          //   name: 'Cabang',
          //   redirect: '/master-data/cabang/list',
          //   component: {
          //     render() {
          //       return h(resolveComponent('router-view'))
          //     },
          //   },
          //   children: [
          //     {
          //       path: '/master-data/cabang/list',
          //       name: 'List Cabang',
          //       component: () => import('@/views/master-data/cabang/ListCabang'),
          //     },
          //     {
          //       path: '/master-data/cabang/tambah',
          //       name: 'Tambah Cabang',
          //       component: () => import('@/views/master-data/cabang/HalamanForm'),
          //     },
          //     {
          //       path: '/master-data/cabang/edit/:id',
          //       name: 'Edit Cabang',
          //       component: () => import('@/views/master-data/cabang/HalamanForm'),
          //     },
          //   ],
          // },
          {
            path: '/master-data/pasien',
            name: 'Pasien',
            redirect: '/master-data/pasien/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/pasien/list',
                name: 'List Pasien',
                component: () => import('@/views/master-data/pasien/ListPasien'),
              },
              {
                path: '/master-data/pasien/tambah',
                name: 'Tambah Pasien',
                component: () => import('@/views/master-data/pasien/HalamanForm'),
              },
              {
                path: '/master-data/pasien/edit/:id',
                name: 'Edit Pasien',
                component: () => import('@/views/master-data/pasien/HalamanForm'),
              },
            ],
          },
          {
            path: '/master-data/staf',
            name: 'Staf',
            redirect: '/master-data/staf/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/staf/list',
                name: 'List Staf',
                component: () => import('@/views/master-data/staf/ListStaf'),
              },
              {
                path: '/master-data/staf/tambah',
                name: 'Tambah Staf',
                component: () => import('@/views/master-data/staf/HalamanForm'),
              },
              {
                path: '/master-data/staf/edit/:id',
                name: 'Edit Staf',
                component: () => import('@/views/master-data/staf/HalamanForm'),
              },
            ],
          },
          {
            path: '/master-data/ruangan',
            name: 'Ruangan',
            redirect: '/master-data/ruangan/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/ruangan/list',
                name: 'List Ruangan',
                component: () => import('@/views/master-data/ruangan/ListRuangan'),
              },
              {
                path: '/master-data/ruangan/tambah',
                name: 'Tambah Ruangan',
                component: () => import('@/views/master-data/ruangan/HalamanForm'),
              },
              {
                path: '/master-data/ruangan/edit/:id',
                name: 'Edit Ruangan',
                component: () => import('@/views/master-data/ruangan/HalamanForm'),
              },
            ],
          },
          {
            path: '/master-data/op',
            name: 'Objek Pemeriksaan',
            redirect: '/master-data/op/list',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: '/master-data/op/list',
                name: 'List',
                component: () => import('@/views/master-data/op/ListOp'),
              },
              {
                path: '/master-data/op/kategori',
                name: 'Kategori',
                component: () => import('@/views/master-data/op/ListOpKategori'),
              },
            ],
          },
          {
            path: '/master-data/user/list',
            name: 'Daftar Pengguna',
            meta: { roles: ['Super User'] },
            component: () => import('@/views/master-data/user/ListUser'),
          },
        ],
      },
      {
        path: '/setting',
        name: 'Settings',
        meta: { roles: ['Super User'] },
        component: () => import('@/views/Settings.vue'),
      },
      {
        path: '/profil/:id/:tipe',
        name: 'Profil',
        meta: { roles: ['Super User', 'Admin', 'Owner', 'Dokter', 'Pasien'] },
        component: () => import('@/views/profil/UserProfile.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/login')
    } else {
      if (to.meta.roles.includes(store.state.authUser?.role)) {
        next()
      } else {
        next('/dashboard')
      }
    }
  } else {
    if (store.state.authUser?.role) {
      next('/dashboard')
    } else {
      next()
    }
  }
})

const isAuthenticated = () => {
  const accessToken = getCookie('accessToken')
  return !!accessToken
}

export default router
