export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'success',
      text: 'NEW',
    },
    permission: '',
    role: '',
  },
  {
    component: 'CNavTitle',
    name: 'Registrasi & Rekam Medis',
    permission: '',
    role: '',
  },
  {
    component: 'CNavItem',
    name: 'Registrasi',
    to: '/registrasi',
    icon: 'cil-cash',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  {
    component: 'CNavGroup',
    name: 'Jadwal & Antrian',
    to: '/jadwal',
    icon: 'cil-calendar',
    permission: '',
    role: ['Super User', 'Admin'],
    items: [
      {
        component: 'CNavItem',
        name: 'Jadwal',
        to: '/jadwal/list',
        permission: '',
        role: ['Super User', 'Admin'],
      },
      {
        component: 'CNavItem',
        name: 'Booking',
        to: '/booking/list',
        permission: '',
        role: ['Super User', 'Admin'],
      },
      {
        component: 'CNavItem',
        name: 'Antrian',
        to: '/antrian/list',
        permission: '',
        role: ['Super User', 'Admin'],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Pemeriksaan & Riwayat',
    to: '/pemeriksaan',
    icon: 'cil-notes',
    permission: '',
    role: ['Super User', 'Admin', 'Dokter'],
    items: [
      {
        component: 'CNavItem',
        name: 'Antrian Pemeriksaan',
        to: '/pemeriksaan/add',
        permission: '',
        role: ['Super User', 'Dokter'],
      },
      {
        component: 'CNavItem',
        name: 'Riwayat Pemeriksaan',
        to: '/pemeriksaan/riwayat',
        permission: '',
        role: ['Super User', 'Admin', 'Dokter'],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Pasien',
    to: '/master-data/pasien/list',
    icon: 'cil-group',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  {
    component: 'CNavTitle',
    name: 'Master Data',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Data Cabang',
  //   to: '/master-data/cabang/list',
  //   icon: 'cil-hospital',
  //   permission: '',
  //   role: ['Super User'],
  // },
  {
    component: 'CNavItem',
    name: 'Data Staf',
    to: '/master-data/staf/list',
    icon: 'cil-group',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  {
    component: 'CNavItem',
    name: 'Data Dokter',
    to: '/master-data/dokter/list',
    icon: 'cil-contact',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'Objek Pemeriksaan',
  //   to: '/master-data/objek-pemeriksaan',
  //   icon: 'cil-notes',
  //   permission: '',
  //   role: ['Super User'],
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Objek Pemeriksaan',
  //       to: '/master-data/op/list',
  //       permission: '',
  //       role: ['Super User'],
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Kategori Objek Pemeriksaan',
  //       to: '/master-data/op/kategori',
  //       permission: '',
  //       role: ['Super User'],
  //     },
  //   ],
  // },
  {
    component: 'CNavItem',
    name: 'Data Ruangan',
    to: '/master-data/ruangan/list',
    icon: 'cil-room',
    permission: '',
    role: ['Super User', 'Admin'],
  },
  {
    component: 'CNavTitle',
    name: 'Setting & Users',
    permission: '',
    role: ['Super User'],
  },
  {
    component: 'CNavItem',
    name: 'Settings',
    to: '/setting',
    icon: 'cil-settings',
    permission: '',
    role: ['Super User'],
  },
  {
    component: 'CNavItem',
    name: 'Data Users',
    to: '/master-data/user/list',
    icon: 'cil-group',
    permission: '',
    role: ['Super User'],
  },
]
