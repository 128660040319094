<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" class="me-2" />
      <span class="float-end" style="line-height: 1.15">
        {{ `${user?.nama || ''}` }}
        <br />
        <small class="text-muted" style="font-size: 0.8em">
          <i>{{ `${user?.cabang || ''} (${user?.role || ''})` }}</i>
        </small>
      </span>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2"> Settings </CDropdownHeader>
      <CDropdownItem @click="$router.push(`/profil/${authUser?.user_id || authUser?.id}/${tipe}`)">
        <CIcon icon="cil-user" /> Profile
      </CDropdownItem>
      <CDropdownItem v-if="authUser?.role == 'Super User'" @click="$router.push('/setting')">
        <CIcon icon="cil-settings" /> Settings
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout()"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/1.png'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  computed: {
    ...mapState(['authUser']),
    tipe() {
      if (['Owner', 'Admin'].includes(this.authUser.role)) {
        return 'staf'
      } else {
        return this.authUser.role.toLowerCase().replace(' ', '-')
      }
    },
  },
  methods: {
    ...mapActions(['prepareLogout']),
    async logout() {
      await this.prepareLogout()
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
